import { EquipmentState } from "../../../components/EquipmentStateLabel/types"
import { MasterRequestState } from "../home/types"

export interface StartWorkRequest {
    stepId: string
    resourceId?: string
}

export interface OperationCompleteRequest {
    stepId: string
}

export interface ArrivalConfirmedRequest {
    resourceId: string
    startWork: boolean
}

export interface MasterRequestDetails {
    steps: MasterRequestStep[]
    resources: Record<string, MasterRequestResource>
}

export interface MasterRequestStep {
    id: string
    wellPadTitle: string
    wellPadGroupTitle: string
    wellTitle: string
    stepTitle: string
    dateTimeOf: string
    dateTimeTo: string
    resourceIds: string[]
    status: MasterRequestState
}

export interface MasterRequestResource {
    title: string
    registrationPlateNumber: string
    resourceState: EquipmentState
    availableToFinish: boolean
    availableToStart: boolean
    currentSlot: {
        currentSlot: {
            id: string
            activityId: string
            type: SlotType
        }
    }

}

export interface TelemetryRequest {
    equipmentIds: string[]
}

export interface EquipmentTelemetry {
    items: EquipmentTelemetryData[]
}

interface EquipmentTelemetryData {
    equipmentId: string
    regNumber: string
    position: {
        lat: number
        lng: number
    }
    speed: number
}

export interface CompleteWorkRequest {
    resourceId: string
    slotId: string
}

export enum SlotType {
    ResourceReturnToBaseLocationSlot = "ResourceReturnToBaseLocationSlot",
    ResourceSupplyBaseLoadingSlot = "ResourceSupplyBaseLoadingSlot",
    ResourceTransportationSlot = "ResourceTransportationSlot",
    ResourceOperationStepSlot = "ResourceOperationStepSlot",
}