import { FC, useRef, useState } from "react";
import { MasterRequest, MasterRequestState, MasterRequestType, SlotType } from "../types";
import { Space } from "../../../../components/Space";
import { RequestStateLabel } from "./RequestStateLabel";
import { Text } from '@consta/uikit/Text';
import { format, isAfter, parseISO } from "date-fns";
import ruLocale from 'date-fns/locale/ru';
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedRequest } from "../../slice";
import { Button } from "@consta/uikit/Button";
import { IconKebab } from '@consta/icons/IconKebab';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { useNavigate } from "react-router";
import { Paths } from "../../../../path";
import { createPortal } from "react-dom";
import { Modal } from '@consta/uikit/Modal';
import { StorageKeys } from "../../../../utils/storage-keys";
import { RootState } from "../../../../store";


type Item = {
    index: number
    label: string
    disabled: boolean
}

interface Props {
    item: MasterRequest
    onDeleted: () => void
    onCanceled: () => void
}


export const MasterRequestItem: FC<Props> = ({ item, onDeleted, onCanceled }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef(null)

    const username = useSelector((state: RootState) => state.user.username)

    const [isContextMenuOpen, setContextMenuOpen] = useState<boolean>(false)
    const [isModalDeleteConfirmOpen, setModalDeleteConfirmOpen] = useState(false)
    const [isModalCancelConfirmOpen, setModalCancelConfirmOpen] = useState(false)

    const pathName = () => {
        switch (item.type) {
            case MasterRequestType.TEAM_TRANSFER:
            case MasterRequestType.TRANSPORTATION:
            case MasterRequestType.ROUTED_TO:
                {
                    return item.locations?.map((location, index) => `${location.wellPadGroupTitle ? location.wellPadGroupTitle + ' /' : ''} ${location.wellPadTitle} ${index == (item.locations?.length ?? 0) - 1 ? '' : ' - '}`)
                }
            case MasterRequestType.JOB:
            case MasterRequestType.SHUTTLE:
                {
                    return item.jobs.map((job, index) => `${job.wellPadGroupTitle ? job.wellPadGroupTitle + ' /' : ''} ${job.wellPadTitle} ${index == item.jobs.length - 1 ? '' : ' - '}`)
                }
            default: {
                return ''
            }
        }
    }

    const deletingDisabled =
        item.hasFactSlots ||
        [
            MasterRequestState.IN_PROGRESS,
            MasterRequestState.COMPLETED,
            MasterRequestState.NOT_PERFORMED,
            MasterRequestState.PART_COMPLETED,
        ].includes(item.state);

    const editingDisabled =
        item.hasFactSlots ||
        [
            MasterRequestState.IN_PROGRESS,
            MasterRequestState.COMPLETED,
            MasterRequestState.NOT_PERFORMED,
            MasterRequestState.PART_COMPLETED,
        ].includes(item.state);


    const cancelDisabled =
        [
            MasterRequestState.NOT_PERFORMED,
            MasterRequestState.COMPLETED,
        ].includes(item.state) || item.createdBy != username;

    const menu: Item[] = (localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false') ? [
        {
            index: 0,
            label: 'Редактировать',
            disabled: editingDisabled,
        },
        {
            index: 1,
            label: 'Удалить',
            disabled: deletingDisabled,
        },
        {
            index: 2,
            label: 'Копировать',
            disabled: false,
        }
    ] : [
        // {
        //     index: 1,
        //     label: 'Удалить',
        //     disabled: deletingDisabled,
        // },
        {
            index: 2,
            label: 'Копировать',
            disabled: false,
        },
        {
            index: 3,
            label: 'Отменить поездку',
            disabled: cancelDisabled,
        }
    ]

    const nowDate = new Date()
    var pickupDate: Date | undefined
    if (item?.pickUpLocation?.planSlots.find(element => element.type == SlotType.ResourceOperationStepSlot)?.beginDate) {
        pickupDate = parseISO(item.pickUpLocation.planSlots.find(element => element.type == SlotType.ResourceOperationStepSlot)!.beginDate)
    }


    return <div>
        <div onClick={() => {
            if (!isContextMenuOpen) {
                dispatch(updateSelectedRequest(item))
            }
        }}>
            <Space
                direction="vertical"
                size="2xs"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <Space spaceBetween>
                    <Space size="xs">
                        <Text view="secondary" weight="regular" size="s">№ {item.reqNum}</Text>
                        <RequestStateLabel state={item.state} />
                    </Space>
                    <Button
                        ref={ref}
                        view="clear"
                        iconLeft={IconKebab}
                        onlyIcon
                        size="s"
                        onClick={(e) => {
                            e.stopPropagation()
                            setContextMenuOpen(true)
                        }} />
                </Space>

                {localStorage.getItem(StorageKeys.passangerModeEnabled) == 'false'
                    ? <Text view="secondary" size="s">{format(parseISO(item.beginDate), "d MMM HH:mm", { locale: ruLocale })} - {format(parseISO(item.endDate), "d MMM HH:mm", { locale: ruLocale })}</Text>
                    : <></>
                }

                {localStorage.getItem(StorageKeys.passangerModeEnabled) == 'true'
                    ? <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Text view="secondary" size="s">{format(parseISO(item.beginDate), "d MMM HH:mm", { locale: ruLocale })}</Text>

                        {(item.state != MasterRequestState.CREATED
                            && item.state != MasterRequestState.CONFIRMED
                            && pickupDate
                            && isAfter(pickupDate, nowDate)
                        ) && <Text
                            view="secondary"
                            weight="semibold"
                            size="s"
                            style={{ paddingLeft: 10 }}
                        >
                                Ожидается ≈ {format(pickupDate, "HH:mm", { locale: ruLocale })}
                            </Text>}
                    </div>

                    : <></>
                }

                <Text view="primary" weight="bold" size="m">{item.name}</Text>

                <Text view="secondary" weight="regular" size="s">{pathName()}</Text>
            </Space>
        </div>

        <ContextMenu
            style={{ zIndex: 300, marginTop: 10 }}
            onClickOutside={(e) => {
                e.stopPropagation()
                setContextMenuOpen(false)
            }}
            isOpen={isContextMenuOpen}
            items={menu}
            anchorRef={ref}
            getItemLabel={(item) => item.label}
            getItemDisabled={(item) => item.disabled}
            direction="downStartLeft"
            onItemClick={(e) => {
                e.e.stopPropagation()
                switch (e.item.index) {
                    case 0:
                        switch (item.type) {
                            case MasterRequestType.JOB:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterJobRequest}/edit/${item.id}`)
                                break
                            case MasterRequestType.SHUTTLE:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterMaterialRequest}/edit/${item.id}`)
                                break
                            case MasterRequestType.ROUTED_TO:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterRouteRequest}/edit/${item.id}`)
                                break
                            case MasterRequestType.TRANSPORTATION:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterTransportationRequest}/edit/${item.id}`)
                                break
                            case MasterRequestType.TEAM_TRANSFER:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterTeamTransferRequest}/edit/${item.id}`)
                                break
                        }
                        break
                    case 1:
                        setModalDeleteConfirmOpen(true)
                        setContextMenuOpen(false)
                        break
                    case 2:
                        switch (item.type) {
                            case MasterRequestType.JOB:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterJobRequest}/copy/${item.id}`)
                                break
                            case MasterRequestType.SHUTTLE:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterMaterialRequest}/copy/${item.id}`)
                                break
                            case MasterRequestType.ROUTED_TO:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterRouteRequest}/copy/${item.id}`)
                                break
                            case MasterRequestType.TRANSPORTATION:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterTransportationRequest}/copy/${item.id}`)
                                break
                            case MasterRequestType.TEAM_TRANSFER:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterTeamTransferRequest}/copy/${item.id}`)
                                break
                            case MasterRequestType.PASSENGER:
                                setContextMenuOpen(false)
                                navigate(`${Paths.masterPassTransferRequest}/copy/${item.id}`)
                                break
                        }
                        break
                    case 3:
                        setModalCancelConfirmOpen(true)
                        setContextMenuOpen(false)
                        break
                    default:
                        setContextMenuOpen(false)
                        break
                }
            }}
        />
        {isContextMenuOpen && createPortal(
            <div
                style={{
                    position: 'fixed',
                    inset: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 299,
                }}
            />,
            document.body
        )}

        <Modal
            isOpen={isModalDeleteConfirmOpen}
            hasOverlay
            onClickOutside={() => setModalDeleteConfirmOpen(false)}
            onEsc={() => setModalDeleteConfirmOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="m" view="secondary" lineHeight="m">Удалить заявку?</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalDeleteConfirmOpen(false)
                                onDeleted()
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalDeleteConfirmOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>

        <Modal
            isOpen={isModalCancelConfirmOpen}
            hasOverlay
            onClickOutside={() => setModalCancelConfirmOpen(false)}
            onEsc={() => setModalCancelConfirmOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="m" view="secondary" lineHeight="m">Отменить поездку?</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalCancelConfirmOpen(false)
                                onCanceled()
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalCancelConfirmOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>
    </div>
}   