export const Paths = {
    root: '/',
    mechanicRequests: '/mechanic-requests',
    driverOfflineRequest: 'driver-new-offline-request',
    masterJobRequest: '/master-job-request',
    masterRouteRequest: '/master-route-request',
    masterTransportationRequest: '/master-transportation-request',
    masterMaterialRequest: '/master-material-request',
    masterTeamTransferRequest: '/master-team-transfer-request',
    masterPassTransferRequest: '/master-passenger-request',
    mechanicBindDevice: '/mechanic-bind-device',
    systemSettings: '/system-settings',
    qrScanner: '/qr-scanner',
} as const