import { mdmApi } from "../mdm/services";
import { StorageKeys } from "./storage-keys";

export function UsePassangerMode(): boolean {
    const { data: systemSettings } = mdmApi.endpoints.getSystemSettings.useQueryState(undefined)

    return systemSettings?.find(element => element.key === 'PASSENGER_MODE_ENABLED' && element.value === 'true') !== undefined
}

export function getDataRefreshPeriod(): number {
    return Number(localStorage.getItem(StorageKeys.dataRefreshPeriod) ?? 15)
}