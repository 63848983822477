import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../../../../base-service'
import { EditMasterTransportationRequest, NewMasterTransportationRequest, UpdateMasterTransportationRequest } from './types'
import { MasterRequest } from '../../home/types'
import { parseISO } from 'date-fns'
import { CalculateDurationFromMinutes } from '../../../../utils/dates'
import { ApiRoutes } from '../../../../utils/api-routes'

export const masterTransportationRequestApi = createApi({
    reducerPath: 'masterTransportationRequestApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        createTransportationRequest: builder.mutation<undefined, NewMasterTransportationRequest>({
            query: (request) => ({
                url: ApiRoutes.createTransportationRequest,
                method: 'POST',
                body: request
            }),
        }),
        updateTransportationRequest: builder.mutation<undefined, UpdateMasterTransportationRequest>({
            query: (request) => ({
                url: ApiRoutes.updateTransportationRequest(request.id),
                method: 'POST',
                body: request.body
            }),
        }),
        getTransportationRequestDetails: builder.query<EditMasterTransportationRequest, string | undefined>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const detailsResponse = await fetchWithBQ(ApiRoutes.getJobRequestDetails(_arg))
                if (detailsResponse.error) {
                    return { error: detailsResponse.error }
                }
                const detailsData = detailsResponse.data as MasterRequest

                const result = {
                    state: detailsData.state,
                    dateTimeOf: parseISO(detailsData.beginDate),
                    dateTimeTo: parseISO(detailsData.endDate),
                    loadDuration: CalculateDurationFromMinutes({ minutes: detailsData.locations?.at(0)?.duration ?? 0 }),
                    unloadDuration: CalculateDurationFromMinutes({ minutes: detailsData.locations?.at(1)?.duration ?? 0 }),
                    loadEquipmentTypes: detailsData.equipmentTypeParamsLoading?.map((element) => ({
                        equipmentType: {
                            id: element.equipmentTypeId,
                            title: element.equipmentTitle
                        },
                        count: element.count
                    })),
                    unloadEquipmentTypes: detailsData.equipmentTypeParamsUnloading?.map((element) => ({
                        equipmentType: {
                            id: element.equipmentTypeId,
                            title: element.equipmentTitle
                        },
                        count: element.count
                    })),
                    transportationEquipmentTypes: detailsData.equipmentTypeParamsTransportations?.map((element) => ({
                        equipmentType: {
                            id: element.equipmentTypeId,
                            title: element.equipmentTitle
                        },
                        count: element.count
                    })),
                    loadLocationId: detailsData.locations?.at(0)?.id,
                    loadPlace: {
                        oilField: {
                            title : detailsData.locations?.at(0)?.wellPadGroupTitle
                        },
                        cluster: {
                            id: detailsData.locations?.at(0)?.locationId,
                            title : detailsData.locations?.at(0)?.wellPadTitle
                        }
                    },
                    unloadLocationId: detailsData.locations?.at(1)?.id,
                    unloadPlace: {
                        oilField: {
                            title : detailsData.locations?.at(1)?.wellPadGroupTitle
                        },
                        cluster: {
                            id: detailsData.locations?.at(1)?.locationId,
                            title : detailsData.locations?.at(1)?.wellPadTitle
                        }
                    }
                } as EditMasterTransportationRequest

                return { data: result }
            }
        }),
    }),
  })
  
  export const {
    useCreateTransportationRequestMutation,
    useGetTransportationRequestDetailsQuery,
    useUpdateTransportationRequestMutation,
} = masterTransportationRequestApi