import { FC } from "react"
import { format, isAfter, isBefore, parseISO } from "date-fns"
import { Text } from '@consta/uikit/Text';
import { ProgressSpin } from '@consta/uikit/ProgressSpin';
import { IconTarget } from '@consta/icons/IconTarget';
import "./style.css";
import { DriverRequest, DriverRequestType, SlotDetails, SlotType } from "../../types";
import { Space } from "../../../../components/Space";



interface Props {
    request: DriverRequest
}

interface Item {
    id: string
    title: string
    date: Date
    endDate: Date | undefined
    completed: boolean
    notPerformed: boolean
    inProgress: boolean
    isTransportationSlot: boolean,
    isStartLocation: boolean,
}

export const DriverRequestTimeline: FC<Props> = ({ request }) => {
    const items: Item[] = []

    var allPlanSlots: SlotDetails[] = []
    var allFactSlots: SlotDetails[] = []

    if (request.type == DriverRequestType.JOB && request?.location) {
        allPlanSlots = request?.location?.planSlots.map(element => element) ?? []
        allFactSlots = request?.location?.factSlots.map(element => element) ?? []
    }

    if (request.type == DriverRequestType.ROUTED_TO && request?.routePoints) {
        allPlanSlots = request?.routePoints?.flatMap(element => element?.planSlots ?? []) ?? []
        allFactSlots = request?.routePoints?.flatMap(element => element?.factSlots ?? []) ?? []
    }

    if (request.type == DriverRequestType.TRANSPORTATION && request?.loadingLocation) {
        allPlanSlots = request?.loadingLocation?.planSlots.map(element => element) ?? []
        allFactSlots = request?.loadingLocation?.factSlots.map(element => element) ?? []
    }

    if (request.type == DriverRequestType.SHUTTLE && request?.materialUnloadingLocation) {
        allPlanSlots = request?.materialUnloadingLocation?.planSlots.map(element => element) ?? []
        allFactSlots = request?.materialUnloadingLocation?.factSlots.map(element => element) ?? []
    }

    if (request.type == DriverRequestType.TEAM_TRANSFER) {
        if (request?.loadingLocation) {
            allPlanSlots = request?.loadingLocation?.planSlots.map(element => element) ?? []
            allFactSlots = request?.loadingLocation?.factSlots.map(element => element) ?? []
        }

        if (request?.unloadingLocations) {
            allPlanSlots = [...allPlanSlots, ...request?.unloadingLocations?.flatMap(element => element?.planSlots ?? []) ?? []]
            allFactSlots = [...allFactSlots, ...request?.unloadingLocations?.flatMap(element => element?.factSlots ?? []) ?? []]
        }
    }

    allPlanSlots.sort((a, b) => parseISO(a.beginDate).getTime() - parseISO(b.beginDate).getTime())

    allPlanSlots.forEach(element => items.push({
        id: element.id,
        date: parseISO(element.beginDate),
        endDate: factSlotEndDate({ slotId: element.id }),
        completed: element.state == 'COMPLETED',
        inProgress: element.state == 'IN_PROGRESS',
        notPerformed: element.state == 'NOT_PERFORMED',
        title: element.type == SlotType.ResourceReturnToBaseLocationSlot || element.type == SlotType.ResourceTransportationSlot ? `${element.titleOf} -> ${element.titleTo}` : `${labels[element.type]}: ${element.titleOf}`,
        isTransportationSlot: element.type == SlotType.ResourceReturnToBaseLocationSlot || element.type == SlotType.ResourceTransportationSlot,
        isStartLocation: false,
    }))

    items.sort((a, b) => a.date.getTime() - b.date.getTime())
    const firstTransportation = items.at(0)?.isTransportationSlot

    function factSlotEndDate({ slotId }: { slotId: string }): Date | undefined {
        const endDate = (allFactSlots as any[]).find((element: any) => element?.id == slotId)?.endDate
        if (endDate) {
            return parseISO(endDate)
        }
        return undefined
    }

    function stepProgressClassName(item: Item): string {
        if (item.inProgress) {
            return 'Loading'
        }

        if (item.completed) {
            return 'LoadingFinished'
        }

        return 'LoadingNotStarted'
    }

    if (firstTransportation) {
        items.unshift({
            id: '0',
            date: new Date(),
            endDate: new Date(),
            completed: false,
            inProgress: false,
            notPerformed: false,
            title: allPlanSlots.at(0)!.titleOf,
            isTransportationSlot: false,
            isStartLocation: true,
        })
    }

    return <Space
        direction="vertical"
        style={{
            position: 'relative',
        }}
    >
        {items.map((item, index) => {
            return <div key={item.id}>
                <Space size="l">
                    {item.isTransportationSlot ?
                        <div
                            className={stepProgressClassName(item)}
                            style={{
                                width: 4,
                                // height: '14%',
                                left: 12,
                                right: '50%',
                                position: 'absolute',
                                marginBottom: 4
                            }}>

                        </div>
                        : <div id={`timeline-point-${index}`} style={{zIndex: 2, backgroundColor: '#ffffff', paddingTop: 4, paddingBottom: 4}}>
                            {(!item.completed && !item.inProgress && !item.notPerformed) ? <IconTarget view="ghost" size="l" /> : <></>}

                            {(item.notPerformed) ? <IconTarget view="alert" size="l" /> : <></>}

                            {(item.completed) ? <IconTarget view="success" size="l" /> : <></>}

                            {item.inProgress ? <ProgressSpin size="l" /> : <></>}
                        </div>}

                    <div
                        id={`timeline-title-${index}`}
                        style={{ marginLeft: !item.isTransportationSlot ? 0 : 48 }}
                    >
                        <Text view='primary' weight="semibold" size='s'>{item.title}</Text>
                        <Space>
                            {
                                item.isStartLocation ? <Text view='secondary' size='s'>Начало маршрута</Text> :
                                    <Text view='secondary' size='s'>{format(item.date, "HH:mm")}</Text>
                            }
                            {item.endDate && isBefore(item.endDate, item.date) ? <Text size='s' view='success' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                            {item.endDate && isAfter(item.endDate, item.date) ? <Text size='s' view='alert' weight="semibold">{format(item.endDate, "HH:mm")}</Text> : <></>}
                        </Space>
                    </div>
                </Space>
            </div>;
        })}
    </Space>

}

const labels: Record<SlotType, string> = {
    [SlotType.ResourceOperationStepSlot]: "Выполняет работы",
    [SlotType.ResourceReturnToBaseLocationSlot]: "",
    [SlotType.ResourceSupplyBaseLoadingSlot]: "Загрузка материалами",
    [SlotType.ResourceSupplyUnloadingSlot]: "Выгрузка материалов",
    [SlotType.ResourceTransportationSlot]: "",
}