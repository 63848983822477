import { FC, useEffect } from "react"
import toast from 'react-hot-toast';
import { Text } from '@consta/uikit/Text';
import { IconInfoCircle } from '@consta/icons/IconInfoCircle';
import { IssueRequest, IssueRequestStatus } from "../../types";
import { useGetScheduleDataQuery } from "../../services";
import { Divider } from "../../../../components/Divider";
import { Space } from "../../../../components/Space";
import SliderButton from "../../../../components/SliderButton";
import { useConfirmResultMutation } from "../services";


interface Props {
    issueRequest: IssueRequest
}

export const DriverStateOfflineRequest: FC<Props> = ({ issueRequest }) => {
    const [confirmResult, { isError: confirmResultIsError, isSuccess: confirmResultIsSuccess, isLoading: confirmResultIsLoading }] = useConfirmResultMutation()
    const { refetch: refetchSchedule, isFetching } = useGetScheduleDataQuery(undefined, { skip: !confirmResult })

    const title: Record<IssueRequestStatus, string> = {
        [IssueRequestStatus.APPROVED]: "Ваша заявка на сход с линии была согласована",
        [IssueRequestStatus.CREATED]: "Ваша заявка на сход с линии в обработке, ожидайте",
        [IssueRequestStatus.DECLINED]: "Ваша заявка на сход с линии была отклонена",
    }

    useEffect(() => {
        if (confirmResultIsError) {
            toast.error('Произошла ошибка. Не удалось выполнить запрос')
        }
    }, [confirmResultIsError])

    useEffect(() => {
        if (confirmResultIsSuccess) {
            refetchSchedule()
        }
    }, [confirmResultIsSuccess])
    
    if (issueRequest?.status == IssueRequestStatus.APPROVED) {
        return <></>
    }


    return <Space
        direction="vertical"
        style={{
            paddingTop: 10,
        }} >
        <Space
            direction="vertical"
            size="l"
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }}
        >
            <Space>
                <IconInfoCircle view="secondary" style={{ height: 42, width: 42 }} />
                <Text size="m">{title[issueRequest?.status]}</Text>
            </Space>


            {issueRequest?.status == IssueRequestStatus.DECLINED ? <SliderButton
                loading={confirmResultIsLoading || isFetching}
                disable={false}
                text='Продолжить работу'
                color='#0078d2'
                onSuccess={() => confirmResult({ requestId: issueRequest.requestId })}
            /> : <></>}
            
        </Space>
        <Divider height={1} />
    </Space>
}