export const StorageKeys = {
    masterWorkPlace: 'MASTER_WORK_PLACE',
    deviceId: 'DEVICE_ID',
    mapCacheArcs: 'MAP_CACHE_ARCS',
    mapCachePoi: 'MAP_CACHE_POI',
    keycloakConfig: 'KEY_CLOAK_CONFIG',
    passangerModeEnabled: 'PASSENGER_MODE_ENABLED',
    offlineData: 'OFFLINE_DATA',
    dataRefreshPeriod: 'DATA_REFRESH_PERIOD',
    driverDowntimePeriod: 'DRIVER_DOWNTIME_PERIOD',
} as const