import { useNavigate } from "react-router"
import { AppBar } from "../../components/AppBar"
import { Space } from "../../components/Space"
import { TextField } from "@consta/uikit/TextField"
import { useState } from "react"
import { Button } from "@consta/uikit/Button"
import { StorageKeys } from "../../utils/storage-keys"
import { useMount } from "react-use"
import toast from "react-hot-toast"


export const SystemSettings = () => {
    const navigate = useNavigate()

    const [refreshPeriod, setRefreshPeriod] = useState<string | null>()
    const [driverDowntimePeriod, setDriverDowntimePeriod] = useState<string | null>()

    useMount(() => {
        setRefreshPeriod(localStorage.getItem(StorageKeys.dataRefreshPeriod) ?? '15')
        setDriverDowntimePeriod(localStorage.getItem(StorageKeys.driverDowntimePeriod) ?? '10')
    })

    return <div>
        <AppBar
            style={{ zIndex: 2 }}
            title="Системные настройки"
            onBackPress={() => navigate(-1)}
        />

        <Space
            direction="vertical"
            style={{
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
                marginTop: 'var(--space-l)',
                paddingTop: 60,
            }}
        >
            <TextField
                value={refreshPeriod}
                onChange={(value) => setRefreshPeriod(value.value ?? '')}
                type="number"
                label='Период обновления данных (сек)'
                placeholder="Секунды"
                incrementButtons={false}
            />

            <TextField
                value={driverDowntimePeriod}
                onChange={(value) => setDriverDowntimePeriod(value.value ?? '')}
                type="number"
                label='Период фиксации простоя водителем (мин)'
                placeholder="Минуты"
                incrementButtons={false}
            />

        </Space>

        <Button
            form="round"
            label="Сохранить"
            style={{
                backgroundColor: '#0078D2',
                position: "absolute",
                bottom: 'var(--space-l)',
                right: 'var(--space-l)',
                left: 'var(--space-l)',
            }}
            onClick={() => { 
                if (refreshPeriod && refreshPeriod.trim().length > 0) {
                    localStorage.setItem(StorageKeys.dataRefreshPeriod, refreshPeriod)
                }

                if (driverDowntimePeriod && driverDowntimePeriod.trim().length > 0) {
                    localStorage.setItem(StorageKeys.driverDowntimePeriod, driverDowntimePeriod)
                }

                toast.success('Настройки сохранены')
                navigate(-1)
            }}
        />
    </div>
}