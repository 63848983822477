import { useSelector } from "react-redux"
import { RootState } from "../../../../../store"
import { EquipmentState } from "../../../../../components/EquipmentStateLabel/types"
import { Text } from '@consta/uikit/Text';


export const EquipmentStateReserveLabel = () => {
    const equipmentState = useSelector((state: RootState) => state.driver.equipmentState)

    switch (equipmentState) {
        case EquipmentState.STATE_RESERVE: {
            return <Text
                weight="semibold"
                size="l"
                spacing="m"
                style={{
                    border: '2px',
                    borderRadius: '2px',
                    position: "absolute",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    backgroundColor: "#97b2c4",
                    textAlign: 'center',
                    height: "50px",
                    color: '#ffffff',
                    alignContent: 'center',
                }}
            >
                В РЕЗЕРВЕ
            </Text>
        }
        case EquipmentState.STATE_OUT_OF_SERVICE: {
            return <Text
                weight="semibold"
                size="l"
                spacing="m"
                style={{
                    border: '2px',
                    borderRadius: '2px',
                    position: "absolute",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    backgroundColor: "#eb3333",
                    textAlign: 'center',
                    height: "50px",
                    color: '#ffffff',
                    alignContent: 'center'
                }}
            >
                СНЯТА С ЛИНИИ
            </Text>
        }
        default: {
            return <></>
        }
    }
}