import { useGetBaseNodeCoordinatesQuery, useGetEquipmentsQuery } from "../services"
import { SsmMap } from "../../../components/Map";
import { Theme, presetGpnDefault } from "@consta/uikit/Theme";
import { EquipmentsHeader } from "./components/EquipmentsHeader";
import { useRef, useState } from "react";
import { IconHamburger } from '@consta/icons/IconHamburger';
import { IconBackward } from '@consta/icons/IconBackward';
import { IconConnection } from '@consta/icons/IconConnection';
import { EquipmentList } from "./components/EquipmentList";
import { Button } from "@consta/uikit/Button";
import { ProfileSideBar } from "../../../components/ProfileSideBar";
import { useDispatch, useSelector } from "react-redux";
import { updateEquipment } from "../slice";
import { EquipmentDetails } from "./components/EquipmentDetails";
import { RootState } from "../../../store";
import { updateSelectedEquipmentPosition } from "../../../components/Map/slice";
import { Space } from "../../../components/Space";
import { useNavigate } from "react-router";
import { Paths } from "../../../path";
import { EquipmentListSkeleton } from "./components/EquipmentListSkeleton";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { BottomSheetDefaultHeader } from "../../../components/BottomSheetDefaultHeader";
import { Divider } from "../../../components/Divider";
import { useInterval } from "react-use";
import { getDataRefreshPeriod } from "../../../utils/settings";


export const MechanicHome = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { data: equipmentsData, isFetching: equipmentsIsLoading, refetch: refetchEquipments } = useGetEquipmentsQuery()
    const { data: baseNodeData } = useGetBaseNodeCoordinatesQuery()

    const [search, setSearch] = useState<string>('');
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const ref = useRef<SheetRef>();

    const selectedEquipment = useSelector((state: RootState) => state.mechanic.selectedEquipment)

    function filteredData({ filter }: { filter: string }) {
        if (equipmentsData) {
            return equipmentsData.filter((equipment) => {
                if (search === '') {
                    return equipment;
                } else {
                    return equipment.registrationPlate.toLowerCase().includes(filter.toLowerCase());
                }
            })
        } else {
            return []
        }
    }

    useInterval(
        () => {
            refetchEquipments();
        },
        getDataRefreshPeriod() * 1000,
    );

    return (<>
        <SsmMap
            center={baseNodeData}
        />

        <Space
            size="l"
            style={{
                position: "absolute",
                left: "10px",
                top: "10px",
            }}>
            {selectedEquipment ? <Button
                label="Назад"
                view="ghost"
                iconLeft={IconBackward}
                onlyIcon
                onClick={() => {
                    dispatch(updateEquipment(null))
                    dispatch(updateSelectedEquipmentPosition(null))
                }}
                style={{
                    backgroundColor: "#ffffff"
                }}

            /> : <Button
                label="Меню"
                view="ghost"
                iconLeft={IconHamburger}
                onlyIcon
                onClick={() => setIsProfileOpen(true)}
                style={{
                    backgroundColor: "#ffffff"
                }}
            />}
            <Button
                label="Заявки"
                view="ghost"
                style={{
                    backgroundColor: "#ffffff"
                }}
                onClick={() => navigate(Paths.mechanicRequests)}
            />

        </Space>

        <ProfileSideBar
            isOpen={isProfileOpen}
            onClickOutside={() => setIsProfileOpen(false)}
            actions={
                [
                    <Button
                        key='bind_btn'
                        size="m"
                        view="clear"
                        label="Привязать устройство"
                        iconLeft={IconConnection}
                        onClick={() => {
                            navigate(Paths.mechanicBindDevice)
                        }}
                    />,
                    <Divider key="div_1"/>
                ]
            }
        />

        <Sheet
            ref={ref}
            isOpen={true}
            onClose={() => { ref.current?.snapTo(1) }}
            snapPoints={[window.innerHeight - 65, 170]}
            detent="full-height"
            initialSnap={1}
            style={{ zIndex: 5 }}
        >
            <Sheet.Container>
                <Sheet.Header>
                    <Theme preset={presetGpnDefault}>
                        {!selectedEquipment ? <EquipmentsHeader
                            refetch={
                                () => {
                                    refetchEquipments()
                                }
                            }
                            search={(value) => { setSearch(value ?? '') }}
                        /> : <BottomSheetDefaultHeader />}
                    </Theme>
                </Sheet.Header>
                <Sheet.Content disableDrag={true}>
                    <Sheet.Scroller draggableAt="top">
                        <Theme preset={presetGpnDefault}>
                            {selectedEquipment && <EquipmentDetails />}

                            {(!selectedEquipment && equipmentsData && !equipmentsIsLoading) && <EquipmentList equipments={filteredData({ filter: search })} />}

                            {((!selectedEquipment && !equipmentsData) || equipmentsIsLoading) && <div style={{ textAlign: 'center', marginTop: 10 }}><EquipmentListSkeleton /></div>}
                        </Theme>
                    </Sheet.Scroller>

                </Sheet.Content>
            </Sheet.Container>
        </Sheet>
    </>)
}