import { GeometryPoint, GeometryPoints } from "../../components/Map/types"
import { MdmMaterial, MdmWorkShift } from "../../mdm/types"

export interface DriverScheduleData {
    schedule: DriverSchedule,
    slot: DriverCurrentSlot,
    shift: MdmWorkShift,
}

export interface DriverSchedule {
    // slots: DriverSlot[] | undefined
    requests: DriverRequest[],
    event: DriverEvent
    issueRequest: IssueRequest
}

export interface DriverCurrentSlot {
    resourceState: EquipmentState
    currentSlot: DriverSlot
    registeredAt: string
    shiftStarted: boolean
    registrationPlateNumber: string
    requestId: string
}

export interface DriverSlot {
    id: string
    beginDate: string
    endDate: string
    resourceId: string
    activityId: string
    roleId: string
    version: number
    type: SlotType
    state: SlotState
    pointOf: GeometryPoints | null
    pointTo: GeometryPoints | null
    poiIdOf: string
    poiIdTo: string
    poiTypeOf: string
    poiTypeTo: string
    titleOf: string
    titleTo: string
    hasMaterials: boolean
    hasDisposalMaterials: boolean
    teamId: string
    sourceOperationStepId: string
    distance: number
    isPlanWithFact: boolean
    wellIdTo: string
    geometry: GeometryPoints
    assignment: boolean
}

export interface IssueRequest {
    equipmentId: string
    requestId: string
    type: IssueRequestType
    modifiedAt: string
    status: IssueRequestStatus
}

export interface DriverEvent {
    id: string
    beginDate: string
    endDate: string
    type: DriverEventType
    comment: string
    resourceId: string
    factSlotId: string
    createdBy: string
}

export enum DriverEventType {
    RESOURCE_DOWNTIME = "RESOURCE_DOWNTIME",
}


interface StatusDetails {
    state: string
    slotId: string
    stepId: string
    registeredAt: string
}

interface Task {
    id: string
    beginDate: string
    endDate: string
    titleOf: string
    titleTo: string
    distanceKm: number
    operationTitle: string
    path: GeometryPoints
    completed: boolean
    status: string
    taskType: string
    materials: MdmMaterial[]
    stepId: string
    isShuttle: boolean
}

enum EquipmentState {
    STATE_WAITING = "STATE_WAITING",
    STATE_LOADING = "STATE_LOADING",
    STATE_ON_ROUTE = "STATE_ON_ROUTE",
    STATE_ARRIVAL_UNCONFIRMED = "STATE_ARRIVAL_UNCONFIRMED",
    STATE_WORK_IN_PROGRESS = "STATE_WORK_IN_PROGRESS",
    STATE_PAUSED = "STATE_PAUSED",
    STATE_OUT_OF_SERVICE = "STATE_OUT_OF_SERVICE",
    STATE_RESERVE = "STATE_RESERVE"
}

export enum SlotState {
    PLANNED = "PLANNED",
    DELAYED = "DELAYED",
    IN_PROGRESS = "IN_PROGRESS",
    LOCKED_AUTO = "LOCKED_AUTO",
    LOCKED_MANUAL = "LOCKED_MANUAL",
    COMPLETED = "COMPLETED",
}

export enum SlotType {
    ResourceReturnToBaseLocationSlot = "ResourceReturnToBaseLocationSlot",
    ResourceSupplyBaseLoadingSlot = "ResourceSupplyBaseLoadingSlot",
    ResourceTransportationSlot = "ResourceTransportationSlot",
    ResourceOperationStepSlot = "ResourceOperationStepSlot",
    ResourceSupplyUnloadingSlot = "ResourceSupplyUnloadingSlot",
}

export enum IssueRequestType {
    GO_OUT_OF_SERVICE = "GO_OUT_OF_SERVICE",
}

export enum IssueRequestStatus {
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
    CREATED = "CREATED",
}

export interface DriverRequest {
    id: string
    name: string
    reqNum: number
    regDt: Date
    type: DriverRequestType
    state: DriverRequestState
    beginDate: string
    endDate: string
    hasFactSlots: boolean
    teamId: string
    teamTitle: string
    contractorTitle: string
    companyTitle: string
    stepTitle: string
    changedBy: string
    createdBy: string
    changeDate: string
    createdSource: string
    changedSource: string
    requestPurpose: string
    phoneNumber: string
    fixOrder: boolean
    numberOfPassengers: number
    equipmentTypeId: string
    routePoints?: Location[]
    location?: Location
    loadingLocation?: Location
    materialUnloadingLocation?: Location
    unloadingLocations?: Location[]
}

export enum DriverRequestType {
    JOB = "JOB",
    TRANSPORTATION = "TRANSPORTATION",
    ROUTED_TO = "ROUTED_TO",
    TEAM_TRANSFER = "TEAM_TRANSFER",
    SHUTTLE = "SHUTTLE",
}

export enum DriverRequestState {
    PLANNED = "PLANNED",
    DELAYED = "DELAYED",
    IN_PROGRESS = "IN_PROGRESS",
    CONFIRMED = "CONFIRMED",
    NOT_PERFORMED = "NOT_PERFORMED",
    LOCKED_AUTO = "LOCKED_AUTO",
    LOCKED_MANUAL = "LOCKED_MANUAL",
    COMPLETED = "COMPLETED",
    LOCKED = "LOCKED",
    DEFICIT = "DEFICIT",
    PART_COMPLETED = "PART_COMPLETED",
}

interface Location {
    destinationPoint: DestinationPoint
    poiId: string
    deleted: boolean
    id: string
    wellPadGroupTitle: string
    wellPadTitle: string
    planSlots: SlotDetails[]
    factSlots: SlotDetails[]
    poiTypes: string[]
}

interface Job {
    destinationPoint: DestinationPoint
    poiId: string
    deleted: boolean
    id: string
    wellPadGroupTitle: string
    wellPadTitle: string
    planSlots: SlotDetails[]
    factSlots: SlotDetails[]
    poiTypes: string[]
}

interface DestinationPoint {
    lat: number
    lng: number
}

export interface SlotDetails {
    id: string
    beginDate: string
    endDate: string
    resourceId: string
    activityId: string
    roleId: string
    version: number
    type: SlotType
    state: DriverRequestState
    pointOf: GeometryPoint
    pointTo: GeometryPoint
    poiIdOf: string
    poiIdTo: string
    poiTypeOf: string
    poiTypeTo: string
    titleOf: string
    titleTo: string
    hasMaterials: boolean
    hasDisposalMaterials: boolean
    assignment: boolean
    distance?: number
}